import Cookies from "universal-cookie";

async function deleteSubscriptionOnServer() {
    const subscription = JSON.parse(localStorage.getItem("pushSubscription"));
    if (!subscription) {
        console.error("No subscription found in localStorage.");
        return;
    }

    await fetch("./register/user/subscription", {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({ subscription }),
    });
}

export async function clearSiteData() {
   
    await deleteSubscriptionOnServer();


    const customLanguage = localStorage.getItem("USER_LANGUAGE");
    localStorage.clear();
    
    if (customLanguage !== null) {
        localStorage.setItem("USER_LANGUAGE", customLanguage);
    }

    const cookies = new Cookies();
    cookies.remove("access_token");
    cookies.remove("id_token");
    cookies.remove("expires_at");

    const otherCookies = document.cookie.split("; ");
    for (var c = 0; c < otherCookies.length; c++) {
        var d = window.location.hostname.split(".");
        while (d.length > 0) {
            var cookieBase = 
              encodeURIComponent(otherCookies[c].split(";")[0].split("=")[0]) + 
              "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" + d.join(".") + 
              " ;path=";
            var p = window.location.pathname.split("/");

            document.cookie = cookieBase + "/";

            while (p.length > 0) {
                document.cookie = cookieBase + p.join("/");
                p.pop();
            }
            d.shift();
        }
    }
}
