import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import FlowItem from "components/FlowItem";

import "./style.scss";

const FlowItems = ({ flowItems, answers, review }) => {

    const { t } = useTranslation();
    const [hasContent, setHasContent] = useState(false);
    const answerKeys = Object.keys(answers);

    const questionComponents = answerKeys.map(key =>
        <FlowItem
            key={key}
            item={flowItems[key]}
            answer={answers[key]}
            review={review ? review[key] : {}}
            onHasContent={() => setHasContent(true)}
        />
    );

    return (
        <div className="report-questions">
            <div className="container-answers">
                {questionComponents}
                {!hasContent &&
                    <>
                        <span className="container-empty">{t("audit.noRemarks")}</span>
                        <div className="spacer" />
                    </>
                }
            </div>
        </div>
    );
};

FlowItems.propTypes = {
    flowItems: PropTypes.object.isRequired,
    answers: PropTypes.object.isRequired,
    review: PropTypes.object.isRequired
};

export default FlowItems;