import client from "../../../graphql/client";
import { loader } from "graphql.macro";
import { snackbarError } from "shared/redux/actions/Snackbar";
import createErrorMessage from "helpers/createErrorMessage";
import { INSPECTIONS_LOADING, INSPECTIONS_ARCHIVED_LOADED } from "redux/types";
import inspectionFromGraphQL from "helpers/inspectionFromGraphQL";

const archivedBookInspections = loader("graphql/queries/archivedBookInspections.graphql");

export default function inspectionsArchivedLoad() {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: INSPECTIONS_LOADING });

            // Use the current organisation to fetch active inspections
            const organisation = getState().organisation.current;

            const response = await client.query({
                query: archivedBookInspections,
                fetchPolicy: "network-only",
                variables: { auditOrganisationId: organisation.id }
            });

            const { archivedBookInspections: archived } = response.data;
            const inspections = {};

            archived.forEach((inspection) => {
                inspections[inspection.id] = inspectionFromGraphQL(inspection);
            });

            dispatch({ type: INSPECTIONS_ARCHIVED_LOADED, inspections });

        } catch (error) {
            console.error(error);
            dispatch(snackbarError(createErrorMessage(error, "inspections.load.failed")));
        }
    };
}
