import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import ListHeaderItem from "./ListHeaderItem/index";

import "./style.scss";

export default function ListHeader({
    items,
    onSort,
    sortIndex,
    sortDirection
}) {

    const renderItems = () => {

        return Array.isArray(items) && items.map((item, index) => {

            const className = classnames("list-header-item",
                items.length - 1 === index && "reverse-direction");

            return (
                <ListHeaderItem
                    key={index}
                    index={index}
                    sort={sortIndex === index}
                    sortDirection={sortDirection}
                    className={className}
                    headerItem={item}
                    onClick={onSort}
                />
            );
        });
    };
    return (
        <div className="okcomply-list-header">
            <div className="list-item-rows">
                {renderItems()}
            </div>
        </div>
    );
}

ListHeader.propTypes = {
    items: PropTypes.array.isRequired,
    onSort: PropTypes.func.isRequired,
    sortIndex: PropTypes.number,
    sortDirection: PropTypes.bool
};
