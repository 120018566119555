import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import Icon, { ICONS } from "shared/components/Icon";
import { useTranslation } from "react-i18next";
import Comment from "components/Comment";
import { REVIEW_RATING } from "constants/audit";
import FlowItems from "./FlowItems";

import "./style.scss";

const Chapter = ({
    chapter,
    answers,
    review
}) => {

    const flowItems = chapter.items;
    const [showAnswers, setShowAnswers] = useState(false);
    const { t } = useTranslation();

    const headerClasses = classNames(
        "container-header",
        showAnswers ? "show" : "hide"
    );

    const { rating } = review;

    const renderRating = () => {

        const approved = rating.rating === REVIEW_RATING.APPROVED;
        const ratingClasses = classNames(
            "rating-indicator",
            approved ? "approved" : "disapproved"
        );

        return (
            <div className="container-conclusion">
                <head>
                    <span>
                        <Icon name={ICONS.CLIPBOARD} spacedRight />
                        Beoordeling
                    </span>
                    <div className={ratingClasses}>
                        <Icon name={approved ? ICONS.CHECK : ICONS.CROSS} />
                    </div>
                </head>
                <div className="container-comment">
                    {rating.comment &&
                        <Comment
                            commentClass="comment-item-report"
                            comment={{
                                comment: rating.comment,
                                user: rating.ratedBy,
                                timestamp: rating.ratedAt
                            }}
                        />
                    }
                </div>
            </div>
        );
    };

    return (
        <div className="report-chapter">
            <div
                className={headerClasses}
                onClick={() => setShowAnswers(!showAnswers)}
            >
                <span>
                    <Icon name={ICONS.BOOK} spacedRight />
                    {chapter.label}
                </span>
                <div className="container-right">
                    <Icon name={showAnswers ? ICONS.UP : ICONS.DOWN} />
                </div>
            </div>
            {showAnswers &&
                <div className="container-content">
                    {rating && renderRating()}
                    <h4>{t("audit.answers")}</h4>
                    <FlowItems
                        flowItems={flowItems}
                        answers={answers}
                        review={review.items}
                    />
                    <h4>{t("audit.resulted.title")}</h4>
                    <>
                        <div className="spacer" />
                        <span className="container-empty">{t("audit.noActionsAndControls")}</span>
                    </>
                </div>
            }
        </div>
    );
};

export default Chapter;

Chapter.propTypes = {
    chapter: PropTypes.object.isRequired,
    answers: PropTypes.object.isRequired,
    review: PropTypes.object.isRequired
};