import client from "../../../graphql/client";
import { loader } from "graphql.macro";
import { REVIEW_COMMENT_PLACED } from "redux/types";
const storeReviewCommentMutation = loader("graphql/mutations/storeReviewComment.graphql");

export function reviewCommentAdd(
    inspectionId,
    chapterId,
    questionId,
    comment
) {
    return async (dispatch, getState) => {

        const user = getState().user.profile;

        const { data } = await client.mutate({
            mutation: storeReviewCommentMutation,
            variables: {
                inspectionId,
                reviewCommentInput: {
                    chapter: chapterId,
                    item: questionId,
                    comment
                }
            }
        });

        const { storeReviewComment: commentResult } = data;
        
        dispatch({
            type: REVIEW_COMMENT_PLACED,
            chapterId,
            questionId,
            key: commentResult.key,
            comment: { ...commentResult, user }
        });

    };
}
