import React from "react";
import InputText from "shared/components/inputs/InputText";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { userMFALogin } from "shared/helpers/mfaLogin";
import { useDispatch } from "react-redux";
import { snackbarError } from "shared/redux/actions/Snackbar";

const AuthMFALogin = ({ onSuccesfullMFALogin }) => {
    const { t } = useTranslation();
    const { control, handleSubmit, errors } = useForm();
    const dispatch = useDispatch();

    const onSubmit = async (formData) => {
        try {
            const { token } = formData;

            const response = await userMFALogin(localStorage.getItem("email"), token);
            if (response.success) {
                onSuccesfullMFALogin();
            }
            else {
                dispatch(snackbarError(t("login.error.message")));
            }
        } catch (error) {
            console.log(error);
            dispatch(snackbarError(t("login.error.message")));
        }
    };

    return (
        <>
            <div className="container-card-centered">

                <form onSubmit={handleSubmit(onSubmit)}>
                    <h4 style={{ fontWeight: "500" }}>{t("userprofile.mfa.verification.code")}</h4>
                    <div className="spacer" />
                    <InputText
                        name="token"
                        label={t("userprofile.mfa.code.label")}
                        defaultValue={""}
                        control={control}
                        rules={{ required: true }}
                        error={errors.token}
                        dataTestId="token"
                    />
                    <input
                        type="submit"
                        className="button is-success is-fullwidth"
                        value={t("userprofile.mfa.code.submit.button.label")}
                    />
                </form>
            </div>
        </>
    );
};

AuthMFALogin.propTypes = {
    onSuccesfullMFALogin: PropTypes.func,
};

export default AuthMFALogin;