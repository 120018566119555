import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import SubscriptionItem from "components/SubscriptionItem";
import ListHeader from "components/ListHeader";
import Loading from "shared/components/Loading";
import { subscriptionsLoad } from "redux/actions/schedule/subscriptionsLoad";
import ScheduleModal from "./ScheduleModal";

import "./style.scss";

export default function InspectionsSchedule() {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { loading, subscriptions, headerItems } = useSelector((state) => state.subscriptions);
    const [scheduling, setScheduling] = useState(null);

    useEffect(() => {
        dispatch(subscriptionsLoad());
    }, []);


    const renderScheduleItems = () => {
        return subscriptions.map((subscription, index) =>
            <SubscriptionItem
                subscription={subscription}
                onSchedule={(subscription) => setScheduling(subscription)}
                index={index}
                key={index}
            />
        );
    };

    const translatedHeaderItems = headerItems.map(headerItem => {
        const { label } = headerItem;
        return { ...headerItem, label: t(label) };
    });

    return (
        <div className="schedule-overview">
            <h2>{t("audit.schedule.title")}</h2>
            <div className="spacer-double" />
            <div className="filter-divider" />
            <div className="okcomply-list-header">
                <div className="list-item-rows">
                    <ListHeader items={translatedHeaderItems} />
                </div>
            </div>
            <div className="audit-list-items">
                {loading ?
                    <Loading /> :
                    renderScheduleItems()
                }
            </div>
            {scheduling &&
                <ScheduleModal
                    onClose={() => setScheduling(null)}
                    subscription={scheduling}
                />
            }
        </div>
    );
}
