import Cookies from "universal-cookie";

export default function isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    const cookies = new Cookies();
    const expiresAt = cookies.get("expires_at");
    const mfaRequired = cookies.get("mfa_required");
    return (new Date().getTime() < expiresAt) && !mfaRequired;
}
