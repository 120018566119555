import React from "react";
import PropTypes from "prop-types";
import Tab from "./Tab";
import { useParams } from "react-router-dom";

import { useSelector } from "react-redux";
import { ROLE_LEVELS } from "constants/roles";

import "./style.scss";

const Modal = ({ title, tabs, basePath, onClose }) => {

    const { tab } = useParams();
    const { user } = useSelector(state => state.user);
    const { role: userRole } = user.membership.okAudit;

    const renderSingleTabContent = () => {
        if (!tab && tabs.length ===1) {
            return tabs[0].component;
        } else {
            return tabs.map((tabItem, role) => {
                if (role && ROLE_LEVELS[userRole] < ROLE_LEVELS[role]) {
                    return "Unauthorized";
                } else {
                    const { component, tabName } = tabItem;
                    return tabName === tab ? component : false;
                }
            });
        }
    };

    const renderTabs = () => {
        return tabs.map(({ tabName, role }, i) => {
            if (role && ROLE_LEVELS[userRole] < ROLE_LEVELS[role]) {
                return null;
            } else {
                return (
                    <Tab
                        basePath={basePath}
                        tabName={tabName}
                        key={i}
                    />
                );
            }
        });
    };

    return (
        <div className="modal-container">
            <div onClick={onClose} className="modal-background" />
            <div className="tabbed-modal">
                <header className="modal-head">
                    <h3 className="modal-title">{title}</h3>
                    <button
                        className="delete is-large"
                        data-testid="closeDossierModal"
                        aria-label="close"
                        onClick={onClose}
                    />
                </header>
                {tabs.length > 1 &&
                    <div className="okcomply-tabs">
                        {renderTabs()}
                    </div>
                }
                <section className="modal-body">
                    {renderSingleTabContent()}
                </section>
            </div>
        </div>
    );
};

Modal.propTypes = {
    title: PropTypes.string.isRequired,
    tabs: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    defaultTab: PropTypes.string,
    basePath: PropTypes.string,
};

export default Modal;