import client from "../../../graphql/client";
import { loader } from "graphql.macro";
import { REVIEW_COMMENT_EDITED } from "../../types";

const editReviewComment = loader("graphql/mutations/editReviewComment.graphql");

export function reviewCommentEdit(
    inspectionId,
    chapterId,
    questionId,
    key,
    comment
) {
    return async (dispatch, getState) => {

        const user = getState().user.profile;

        const { data } = await client.mutate({
            mutation: editReviewComment,
            variables: {
                inspectionId,
                key,
                reviewCommentInput: {
                    chapter: chapterId,
                    item: questionId,
                    comment
                }
            }
        });

        const { editReviewComment: commentResult } = data;
        
        dispatch({
            type: REVIEW_COMMENT_EDITED,
            chapterId,
            questionId,
            key: commentResult.key,
            comment: { ...commentResult, user }
        });

    };
}
