import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Icon, { ICONS } from "shared/components/Icon";
import { localeDate } from "helpers/formattedDate";
import { dossierLoad } from "redux/actions/dossier/dossierLoad";
import calculateDueDateWithEvery from "helpers/calculateDueDateWithEvery";
import { dossierActivityLoad } from "redux/actions/dossier/dossierActivityLoad";

import "./style.scss";

const DISPLAY_EVENTS = [
    "dossierApproved",
    "dossierCreated",
    "dossierDisapproved"
];
const DossierItem = ({ draftDossierId, inspectionId }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState(false);
    let completedTasks = 0;

    const { dossiers } = useSelector(state => state.dossiers);
    const dossier = dossiers[draftDossierId];

    useEffect(() => {
        if (!dossier) {
            dispatch(dossierLoad(inspectionId, draftDossierId));
        }
    }, []);

    if (!dossier) {
        return null;
    }

    const {
        label,
        approved,
        initiationDate,
        every,
        tasks,
        isDraft
    } = dossier;


    const deadline = calculateDueDateWithEvery(initiationDate, every);

    if (tasks) {
        tasks.forEach((task) => {
            task.completed && completedTasks++;
        });
    }

    const summaryClasses = classnames(
        "summary",
        isOpen ? "open" : "closed",
    );

    const dossierClasses = classnames(
        "dossier-content",
        approved ? "approved" :
            deadline < new Date() ? "late" : ""

    );

    const showActivity = () => {
        const { activity } = dossier;
        if (!activity) return null;

        return (
            <div className="container-activity">
                {activity.map(({ event, timestamp }) => {
                    return (
                        DISPLAY_EVENTS.includes(event) &&
                        <div className="activity-item">
                            <Icon name={ICONS.CLOCK} />
                            <span className="event">{t(`audit.dossier.activity.${event}`)}</span>
                            <span className="timestamp">{localeDate(parseInt(timestamp))}</span>
                        </div>
                    );
                })}
            </div>
        );
    };

    const onToggleActivity = () => {
        if (!dossier.activity) {
            dispatch(dossierActivityLoad(inspectionId, draftDossierId, dossier.dossierId));
        }
        setOpen(!isOpen);
    };


    return (
        <div className="audit-dossier-item">
            <div className={summaryClasses}>
                <div className="icon">
                    <Icon name={ICONS.DOSSIER} spacedRight />
                </div>
                <div className={dossierClasses}>
                    {label}
                    <div>
                        {
                            isDraft ?
                                <span>{t("audit.dossier.isDraft")}</span> :
                                <>
                                    <span>{localeDate(deadline)}</span>
                                    {tasks &&
                                        <span className="tasks">
                                            {`${completedTasks}/${tasks.length}
                                  ${t("tasks.plural")}`}
                                        </span>
                                    }
                                </>
                        }
                    </div>
                </div>
                {!isDraft &&
                    <div className="button-collapse" onClick={onToggleActivity}>
                        <Icon name={isOpen ? ICONS.UP : ICONS.DOWN} />
                    </div>
                }
            </div>
            {isOpen && showActivity()}

        </div>
    );
};

DossierItem.propTypes = {
    draftDossierId: PropTypes.string.isRequired,
    inspectionId: PropTypes.string.isRequired
};

export default DossierItem;