import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import reviewLoadCurrent from "redux/actions/review/reviewLoadCurrent";
import Chapter from "./Chapter";
import Resolution from "./Resolution";
import Sidebar from "./Sidebar";
import MobileNavigation from "shared/components/MobileNavigation";

import "./style.scss";

const renderSection = (section) => {
    switch (section) {
        case "review":
            return <Chapter />;
        case "resolution":
            return <Resolution />;
        default:
            return null;
    }
};

const Review = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { inspectionId, section } = useParams();

    useEffect(() => {
        dispatch(reviewLoadCurrent(inspectionId));
    }, [dispatch]);

    return (
        <div className="audit-details">
            <Sidebar />
            <div className="audit-content">
                <MobileNavigation title={t("review.mobileNavigation")} />
                {renderSection(section)}
            </div>
        </div>
    );
};

export default Review;