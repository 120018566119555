import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import DotMenu from "shared/components/DotMenu";

import "./style.scss";

const ContactItem = ({
    contact,
    index,
    onDelete,
    onEdit
}) => {

    const { t } = useTranslation();
    const { name, surname, phoneNumber, email } = contact;

    return (
        <div className="contact-item">
            <div className="contact-info">
                <span className="name">{`${name} ${surname}`}</span>
                <span className="phoneNumber">{phoneNumber}</span>
            </div>
            <span className="email">{email}</span>
            <DotMenu menuItems={[
                { label: t("contact.edit"), action: () =>  onEdit(index) },
                { label: t("contact.delete"), action: () =>  onDelete(index) },
            ]}
            />
        </div>
    );
};

ContactItem.propTypes = {
    contact: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired
};

export default ContactItem;