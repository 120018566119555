import client from "../../../graphql/client";
import { loader } from "graphql.macro";
import { snackbarError } from "shared/redux/actions/Snackbar";
import createErrorMessage from "helpers/createErrorMessage";
import { auditSaveinspections } from "redux/actions/audit/index";
import { INSPECTIONS_LOADING } from "redux/types";

const bookInspectionsQuery = loader("graphql/queries/bookInspections.graphql");

export default function inspectionsLoad() {

    return async (dispatch, getState) => {
        try {

            dispatch({ type: INSPECTIONS_LOADING });

            // Use the current organisation to fetch active inspections
            const organisation = getState().organisation.current;
            const filters = getState().audit.filters;

            const response = await client.query({
                query: bookInspectionsQuery,
                fetchPolicy: "network-only",
                variables: {
                    auditOrganisationId: organisation.id,
                    ...filters
                }
            });

            const { bookInspections } = response.data;
            const inspections = {};

            bookInspections.forEach((inspection) => {

                const { review, book, resolution } = inspection;
                const { chapters } = book;
                
                inspections[inspection.id] = {
                    ...inspection,
                    review: review ? {
                        ...review,
                        progressState: {
                            ...review.progressState,
                            chapters: JSON.parse(review.progressState.chapters),
                            preFlow: JSON.parse(review.progressState.preFlow)
                        },
                        reviewData: JSON.parse(review.reviewData)
                    } : null,
                    resolution: JSON.parse(resolution),
                    book: {
                        ...book,
                        chapters: JSON.parse(chapters)
                    },
                    // Fun stuff because we are using graphql \o/
                    notes: inspection.notes
                        .map(({ timestamp, userId, text }) => {
                            return { timestamp, userId, text };
                        }),
                    contacts: inspection.contacts
                        .map(({ name, surname, email, phoneNumber }) => {
                            return { name, surname, email, phoneNumber };
                        }),
                };
            });

            dispatch(auditSaveinspections(inspections));


        } catch (error) {
            console.error(error);
            dispatch(snackbarError(createErrorMessage(error, "inspections.load.failed")));
        }
    };

}