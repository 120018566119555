import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import Icon, { ICONS } from "shared/components/Icon";
import RatingButtons from "components/RatingButtons";
import FlowItem from "components/FlowItem";
import RatingModal from "containers/Review/RatingModal";

import "./style.scss";

const FlowItems = ({
    chapterLabel,
    flowItems,
    answers,
    review
}) => {

    const [t] = useTranslation();
    const answerKeys = Object.keys(answers);
    const [isRating, setIsRating] = useState(false);
    const [isApproved, setIsApproved] = useState(false);

    const [showAnswers, setShowAnswers] = useState(true);
    const headerClasses = classNames(
        "container-header",
        showAnswers ? "show" : "hide"
    );

    const onApprove = (e) => {
        setIsRating(true);
        setIsApproved(true);
        // Keeps the dropdown from closing
        e.stopPropagation();
    };

    const onDisapprove = (e) => {
        setIsRating(true);
        setIsApproved(false);
        // Keeps the dropdown from closing
        e.stopPropagation();
    };

    const { items: reviewItems } = review;
    const { rating, comment: ratingComment } = review?.rating || {};

    return (
        <div className="audit-flow-items">
            <div className={headerClasses} onClick={() => setShowAnswers(!showAnswers)}>
                <span>
                    <Icon name={ICONS.CLIPBOARD} spacedRight />
                    {t("audit.answers")}
                </span>
                <div className="container-right">
                    <RatingButtons
                        rating={rating}
                        onApprove={onApprove}
                        onDisapprove={onDisapprove}
                    />
                    <Icon name={showAnswers ? ICONS.UP : ICONS.DOWN} />
                </div>
            </div>
            {showAnswers &&
                <div className="container-answers">
                    {answerKeys.map(key =>
                        <FlowItem
                            key={key}
                            itemId={key}
                            item={flowItems[key]}
                            answer={answers[key]}
                            review={reviewItems ? reviewItems[key] : {}}
                        />
                    )}
                </div>
            }
            {isRating &&
                <RatingModal
                    label={chapterLabel}
                    defaultValue={ratingComment}
                    approved={isApproved}
                    onClose={() => setIsRating(false)}
                />
            }
        </div>
    );
};

FlowItems.propTypes = {
    chapterLabel: PropTypes.string.isRequired,
    flowItems: PropTypes.object.isRequired,
    answers: PropTypes.object.isRequired,
    review: PropTypes.object.isRequired
};

export default FlowItems;