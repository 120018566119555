export async function userMFALogin(email, token) {
    const parameters = {
        headers: {
            "content-type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify({
            email: email,
            token: token
        }),
        method: "POST"
    };
    const response = await fetch("/register/user/mfa/login", parameters);

    return await response.json();
}