import client from "../../../graphql/client";
import { loader } from "graphql.macro";
import { snackbarError } from "shared/redux/actions/Snackbar";
import createErrorMessage from "helpers/createErrorMessage";
import { INSPECTION_HISTORY_LOADED } from "redux/types";
import inspectionFromGraphQL from "helpers/inspectionFromGraphQL";

const historyBookInspections = loader("graphql/queries/historyBookInspections.graphql");

export default function inspectionHistoryLoad(subscriptionId) {
    return async (dispatch, getState) => {
        try {
            // Use the current organisationId to fetch the inspection history
            const { id: auditOrganisationId } = getState().organisation.current;

            const response = await client.query({
                query: historyBookInspections,
                variables: {
                    auditOrganisationId,
                    subscriptionId
                }
            });

            const { historyBookInspections: bookInspections } = response.data;
            const inspections = [];

            bookInspections.forEach((inspection) => {
                inspections.push(inspectionFromGraphQL(inspection));
            });

            inspections.sort((b, a) => {
                return new Date(a.completeDate).getTime()
                     - new Date(b.completeDate).getTime();
            });

            dispatch({
                type: INSPECTION_HISTORY_LOADED,
                inspections: inspections
            });

        } catch (error) {
            console.error(error);
            dispatch(snackbarError(createErrorMessage(error, "inspections.load.failed")));
        }
    };
}