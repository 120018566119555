import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Button from "shared/components/buttons/Button";
import InputText from "shared/components/inputs/InputText";
import ButtonAnchor from "shared/components/buttons/ButtonAnchor";
import { ICONS } from "shared/components/Icon";
import { ENVIRONMENT_URL } from "constants/environment";
import { useTranslation } from "react-i18next";
import inspectionSendReportEmail from "redux/actions/audit/inspectionSendReportEmail";
import downloadFile from "shared/helpers/downloadFile";
import { useForm } from "react-hook-form";

import "./style.scss";

const ModalReportSend = ({ inspection, onClose }) => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { control, handleSubmit, errors } = useForm();

    const { current: organisation } = useSelector(state => state.organisation);
    const { node, contacts, resolution } = inspection;

    const { file } = resolution;
    const { email, name } = contacts[0] || {};

    const [emailName, setEmailName] = useState(name);
    const [emailAddress, setEmailAddress] = useState(email);

    // Send the report email from the server
    const sendReportEmail = async () => {
        await dispatch(inspectionSendReportEmail(
            inspection.id,
            emailAddress || name,
            emailName || email
        ));
        onClose();
    };

    // Open the email client to send from own e-mail
    const openEmailClient = () => {
        const subject = t("audit.email.subject");
        const mailBody = `${t("audit.email.salutation")} ${emailName},
        %0D%0A%0D%0A${t("audit.email.explanation")}
        %0D%0A${ENVIRONMENT_URL}?redirect=/organisation/node/${node.id}/info
        %0D%0A%0D%0A${t("audit.email.closing")}`;

        window.location.href = `mailto:${emailAddress}?subject=${subject}&body=${mailBody}`;
    };

    const renderContent = () => {
        return (
            <>
                <p>{t("audit.review.finished.text")}</p>
                <div className="spacer" />
                <ButtonAnchor
                    label={t("audit.review.downloadReport")}
                    icon={ICONS.DOWNLOAD}
                    onClick={() => downloadFile(file, organisation.id, false)}
                />
                <div className="spacer" />
                <form onSubmit={handleSubmit(data => openEmailClient(data))}>
                    <InputText
                        label={t("audit.review.name.label")}
                        onChange={({ target }) => setEmailName(target.value)}
                        defaultValue={emailName}
                        control={control}
                        error={errors.name}
                        rules={{ required: true }}
                        name="name"
                    />
                    <InputText
                        label={t("audit.review.email.label")}
                        onChange={({ target }) => setEmailAddress(target.value)}
                        defaultValue={emailAddress}
                        control={control}
                        error={errors.email}
                        rules={{ required: true }}
                        name="email"
                    />
                </form>
                <div className="spacer" />
                <div className="cols">
                    <div className="col">
                        <Button
                            secondary
                            outlined
                            fullWidth
                            label={t("audit.review.sendEmail")}
                            onClick={() => handleSubmit(sendReportEmail)()}
                        />
                    </div>
                    <div className="col">
                        <Button
                            secondary
                            fullWidth
                            label={t("audit.review.openEmail")}
                            onClick={() => handleSubmit(openEmailClient)()}
                        />
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className="report-modal-container">
            <div onClick={onClose} className="modal-background" />
            <div className="report-modal">
                <header className="modal-head">
                    <h3 className="modal-title">
                        {t("audit.review.finished.title")}
                    </h3>
                    <button
                        className="delete is-large"
                        aria-label="close"
                        onClick={onClose}
                    />
                </header>
                <div className="modal-body">
                    {renderContent()}
                </div>
            </div>
        </div>
    );
};

ModalReportSend.propTypes = {
    inspection: PropTypes.object.isRequired,
    onClose: PropTypes.func
};

export default ModalReportSend;