import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link, useParams } from "react-router-dom";
import Icon from "shared/components/Icon";
import { ICONS } from "shared/components/Icon";
import { REVIEW_RATING } from "constants/audit";

const ChapterItem = ({ chapter, id, rating }) => {

    const { inspectionId, id: chapterId } = useParams();
    
    const classes = classNames(
        "sidebar-item",
        chapterId === id && "active",
        rating === REVIEW_RATING.APPROVED  && "approved",
        rating === REVIEW_RATING.DISAPPROVED && "disapproved",
        !rating  && "unrated",
    );

    const ratingIcons = {
        [REVIEW_RATING.DISAPPROVED] : ICONS.CROSS,
        [REVIEW_RATING.APPROVED]: ICONS.CHECK,
    };

    return (
        <Link
            to={`/inspection/${inspectionId}/review/${id}`}
            className={classes}
        >
            <div className="indicator">
                {rating && <Icon name={ratingIcons[rating]} size="xs" />}
            </div>
            {chapter.label}
        </Link>
    );
};

ChapterItem.propTypes = {
    chapter: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    rating: PropTypes.number,
};

export default ChapterItem;