export async function requireUserMfa(email) {
    const parameters = {
        headers: {
            "content-type": "application/json; charset=UTF-8"
        },
        method: "POST"
    };
    const response = await fetch(`/register/user/mfa/require/${email}`, parameters);

    return await response.json();
}