import client from "../../../graphql/client";
import { loader } from "graphql.macro";
import { snackbarError, snackbarSuccess } from "shared/redux/actions/Snackbar";
import createErrorMessage from "helpers/createErrorMessage";
import inspectionsLoad from "../audit/inspectionsLoad";
import { subscriptionsLoad } from "./subscriptionsLoad";

const addBookInspectionMutation = loader("graphql/mutations/addBookInspection.graphql");

export function inspectionSchedule(scheduleData) {
    return async (dispatch) => {
        try {
            await client.mutate({
                mutation: addBookInspectionMutation,
                variables: scheduleData
            });

            // Right now we simply reload the entire list, this is to make
            // sure everything is updated correctly.
            await dispatch(snackbarSuccess("audit.inspection.created"));
            await dispatch(subscriptionsLoad());
            await dispatch(inspectionsLoad());
            
        } catch(error) {
            console.error(error);
            dispatch(snackbarError(createErrorMessage(error)));
        }
    };
}
