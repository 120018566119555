import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import OkWarning from "shared/components/OkWarning";
import InputText from "shared/components/inputs/InputText";
import Button from "shared/components/buttons/Button";
import ButtonAnchor from "shared/components/buttons/ButtonAnchor";
import Icon, { ICONS } from "shared/components/Icon";
import { snackbarError } from "shared/redux/actions/Snackbar";
import userRegister from "shared/helpers/userRegister";

const AuthRegister = ({ setRegistration, setEmail }) => {

    const { t } = useTranslation();
    const [confirm, setConfirm] = useState(false);
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        errors,
        watch,
        setError,
        clearErrors
    } = useForm();

    const onSubmit = async (formData, e) => {
        e.preventDefault();
        const result = await userRegister(formData);
        const { success, registrationStatus } = result;
        if (success) {
            setConfirm(true);
        } else {
            // Remove dot at end of string
            const description = registrationStatus.replace(/\.$/, "");
            dispatch(snackbarError(description));
        }

    };

    const renderConfirmAccount = () => {
        return (
            <OkWarning
                onConfirm={undefined}
                title={t("authentication.registration.title")}
                message={t("authentication.registration.message")}
            />);
    };

    const validatePasswordCheck = (value) => {
        if (!value || value !== watch("password")) {
            return t("validation.password.noMatch");
        } else {
            return;
        }

    };

    const validatePassword = ({ target }) => {
        const { value } = target;
        if (value && !value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}/)) {
            setError("password", { message: t("formValidation.passwordStrength") });
        } else {
            clearErrors("password");
        }
    };

    const validatePhoneNumber = (value) => {
        const regex = /^(\+31|0|0031)(6|7)\d{8}$/;
        return regex.test(value) || t("user.phone.invalid"); 
    };

    const onChange = (event) => {
        setEmail(event.target.value);
    };

    const renderRegister = () => {
        return (
            <>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <h4>{t("common.register")}</h4>
                    <div className="spacer" />
                    <div className="cols">
                        <div className="col">
                            <InputText
                                label={t("user.name.label")}
                                name="name"
                                error={errors.name}
                                inputRef={register({ required: true })}
                            />
                        </div>
                        <div className="col">
                            <InputText
                                label={t("user.surname.label")}
                                name="surname"
                                error={errors.surname}
                                inputRef={register({ required: true })}
                            />
                        </div>
                    </div>
                    <InputText
                        label={t("user.email.label")}
                        name="email"
                        onChange={onChange}
                        error={errors.email}
                        inputRef={register({ required: true })}
                    />
                    <InputText
                        label={t("user.phone.label")}
                        name="phone"
                        onChange={onChange}
                        error={errors.phone}
                        inputRef={register({
                            required: true,
                            validate: validatePhoneNumber
                        })}
                    />
                    <InputText
                        label={t("common.password.singular")}
                        name="password"
                        error={errors.password}
                        type="password"
                        onBlur={validatePassword}
                        inputRef={register({ required: true })}
                    />
                    <InputText
                        label={t("common.password.validation")}
                        name="passwordCheck"
                        error={errors.passwordCheck}
                        type="password"
                        inputRef={register({
                            validate: validatePasswordCheck
                        })}
                    />
                    <input
                        type="submit"
                        className="button is-success is-fullwidth"
                        value={t("common.register")}
                    />
                    <div className="spacer-double" />
                    <p className="passwordstrengtherror">
                        <span>
                            <Icon name={ICONS.WARNING} />
                        </span>
                        {t("auth0.error.passwordstrengthinfo")}
                    </p>
                </form>
                <div className="is-divider" />
                <h4>{t("authentication.alreadyHaveAnAccount")}</h4>
                <Button
                    label={t("button.login")}
                    outlined
                    fullWidth
                    onClick={() => setRegistration(false)}
                />
                <div className="is-divider" />
                <ButtonAnchor
                    label={t("authentication.help.register.label")}
                    icon={ICONS.WARNING}
                    onClick={() => window.location = t("authentication.help.register.link")}
                />
            </>);
    };


    const renderFlow = () => {

        if (confirm) {
            return renderConfirmAccount();
        } else {
            return renderRegister();
        }

    };

    return (
        <div className="container-card-centered">
            {renderFlow()}
        </div>
    );
};

AuthRegister.propTypes = {
    setRegistration: PropTypes.func.isRequired,
    setEmail: PropTypes.func.isRequired,
    email: PropTypes.string
};


export default AuthRegister;
