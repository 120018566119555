import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";

const LanguageSelector = () => {
    const { i18n } = useTranslation();
    const browserLanguage = navigator.language || navigator.userLanguage;
    const initialLanguage = browserLanguage.startsWith("nl") ? "nl" : "en";
    const storedLanguage = localStorage.getItem("USER_LANGUAGE") || initialLanguage;

    const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage.toUpperCase());
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const currentLanguageRef = useRef(storedLanguage.toLowerCase());

    const languages = [
        { code: "en", name: "English" },
        { code: "nl", name: "Dutch" },
    ];

    // Change language when component mounts
    useEffect(() => {
        // Check if current language is different from the stored language
        if (currentLanguageRef.current !== storedLanguage.toLowerCase()) {
            i18n.changeLanguage(storedLanguage.toLowerCase());
            currentLanguageRef.current = storedLanguage.toLowerCase(); // Update ref
        }
    }, [i18n, storedLanguage]); // Only run on mount

    const handleLanguageChange = (lang) => {
        const formattedLang = lang.toLowerCase();

        // Only update state and language if different
        if (formattedLang !== i18n.language) {
            setSelectedLanguage(formattedLang.toUpperCase());
            i18n.changeLanguage(formattedLang);
            localStorage.setItem("USER_LANGUAGE", formattedLang);
            currentLanguageRef.current = formattedLang; // Update ref
            setDropdownOpen(false);
        }
    };

    return (
        <div className="language-selector">
            <div className="selected-language" onClick={() => setDropdownOpen(!dropdownOpen)}>
                {selectedLanguage}
            </div>

            {dropdownOpen && (
                <ul className="language-dropdown">
                    {languages.map((lang) => (
                        <li key={lang.code} onClick={() => handleLanguageChange(lang.code)}>
                            {lang.name}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default LanguageSelector;