import client from "../../setupClient";
import { fetchMFADataQuery } from "shared/graphql/queries/mfa";

const getEnvironment = () => {
    const isDev = (!process.env.NODE_ENV || process.env.NODE_ENV === "development");
    return isDev ? "development" :
        window.location.href.includes("acceptance") ? "acceptance" :
            "production";
};

const ENVIRONMENTS = {
    development: "http://localhost:1337/",
    acceptance: "https://acceptance.okcomply.com/",
    production: "https://my.okcomply.com/"
};

export function fetchMFAData() {
    return () => {
        return client.query({
            query: fetchMFADataQuery,
            fetchPolicy: "network-only"
        });
    };
} 

export async function fetchMFADataUnauth(email) {
  
    const parameters = {
        headers: {
            "content-type": "application/json; charset=UTF-8"
        },
        method: "GET"
    };

    const currentEnvironment = getEnvironment();
    const response = await fetch(`${ENVIRONMENTS[currentEnvironment]}register/user/mfa/${email}`, parameters);

   
    return await response.json();
}