export default function inspectionFromGraphQL(inspection) {

    const { review, book, resolution } = inspection;
    const { chapters } = book;

    return {
        ...inspection,
        review: review ? {
            ...review,
            progressState: {
                ...review.progressState,
                chapters: JSON.parse(review.progressState.chapters)
            },
            reviewData: JSON.parse(review.reviewData)
        } : null,
        resolution: JSON.parse(resolution),
        book: {
            ...book,
            chapters: JSON.parse(chapters)
        },
        // We have to get rid of some additional meta data as a result of using 
        // GraphQL. 
        notes: inspection.notes
            .map(({ timestamp, userId, text }) => {
                return { timestamp, userId, text };
            }),
        contacts: inspection.contacts
            .map(({ name, surname, email, phoneNumber }) => {
                return { name, surname, email, phoneNumber };
            }),
    };
}