import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Modal from "components/Modal";
import TabInfo from "./tabs/TabInfo";
import TabHistory from "./tabs/TabHistory";
import TabEdit from "./tabs/TabEdit";
import { ROLES } from "constants/roles";

import "./style.scss";

const InspectionModal = () => {

    const { t } = useTranslation();
    const { inspectionId } = useParams();
    const history = useHistory();
    const auditReducer = useSelector(state => state.audit);

    const { inspections, archived } = auditReducer;
    const inspection = archived[inspectionId] || inspections[inspectionId];
    const pathPrefix = inspection.archived ? "/archived" : "/inspections";

    const onClose = () => history.push(pathPrefix);

    const tabs = [
        {
            tabName: "info",
            component:
                <TabInfo
                    inspection={inspection}
                    key="info"
                />
        },
        {
            tabName: "history",
            component:
                <TabHistory
                    inspection={inspection}
                    key="info"
                />
        },
        {
            tabName: "edit",
            role: ROLES.QUALITY_MANAGER,
            component:
                <TabEdit
                    inspection={inspection}
                    onClose={onClose}
                    key="info"
                />
        },
    ];

    return (
        <div className="inspection-details">
            <Modal
                title={inspection?.organisation?.label || t("inspection.unknown")}
                basePath={`${pathPrefix}/${inspectionId}`}
                tabs={tabs}
                onClose={onClose}
            />
        </div>
    );
};

export default InspectionModal;