import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import inspectionsFilter from "redux/actions/audit/inspectionsFilter";
import InputSelect from "shared/components/inputs/InputSelect";
import InputText from "shared/components/inputs/InputText";
import InputDate from "shared/components/inputs/InputDate";
import usersToSelectOptions from "helpers/usersToSelectOptions";

import { PROVINCES } from "constants/audit";
import { ROLES } from "constants/roles";

import "./style.scss";

const FilterInspections = ({ onClose }) => {

    const { books } = useSelector(state => state.books);
    const { filters } = useSelector(state => state.audit);
    const { users } = useSelector(state => state.users);
    const { user } = useSelector(state => state.user);

    const userOptions = usersToSelectOptions(users);

    const [timeoutRef, setTimeoutRef] = useState();

    const [currentFilters, setCurrentFilters] = useState(filters);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        clearTimeout(timeoutRef);
        const ref = setTimeout(() => {
            dispatch(inspectionsFilter(currentFilters));
        }, 500);
        setTimeoutRef(ref);
    }, [currentFilters, dispatch]);

    const { okAudit } = user.membership;

    const bookKeys = Object.keys(books);
    const bookItems = bookKeys.map((key) => {
        const { label, osbn: value } = books[key];
        return { label, value };
    });

    const onChange = async ({ target }) => {
        const { name, value } = target;
        await setCurrentFilters({ ...currentFilters, [name]: value });
    };

    return (
        <div className="filter-inspections">
            <header className="filter-head">
                <h3 className="light">{t("invites.filter.title")}</h3>
                <button
                    className="delete is-large"
                    data-testid="closeInviteFilter"
                    aria-label="close"
                    onClick={onClose}
                />
            </header>
            <div className="spacer" />
            <div className="cols">
                <div className="col">
                    <InputText
                        label={t("inspection.filter.organisationName")}
                        name="organisationName"
                        defaultValue={filters?.organisationName}
                        onChange={onChange}
                        dark
                    />
                </div>
                <div className="col">
                    <InputSelect
                        items={bookItems}
                        defaultValue={filters?.osbn}
                        label={t("inspection.filter.book")}
                        name="osbn"
                        onChange={onChange}
                        dark
                    />
                </div>
                <div className="col">
                    <InputText
                        label={t("inspection.filter.city")}
                        name="city"
                        defaultValue={filters?.city}
                        onChange={onChange}
                        dark
                    />
                </div>
                <div className="col">
                    <InputSelect
                        items={PROVINCES}
                        label={t("inspection.filter.province")}
                        name="province"
                        defaultValue={filters?.province}
                        onChange={onChange}
                        dark
                    />
                </div>
            </div>
            <div className="cols">
                <div className="col">
                    <InputDate
                        label={t("inspection.filter.dateStart")}
                        defaultValue={filters?.dateStart}
                        dark
                        onChange={(date) => {
                            onChange({
                                target: {
                                    name: "dateStart",
                                    value: date
                                }
                            })
                        }}
                    />
                </div>
                <div className="col">
                    <InputDate
                        label={t("inspection.filter.dateEnd")}
                        defaultValue={filters?.dateEnd}
                        dark
                        onChange={(date) => {
                            onChange({
                                target: {
                                    name: "dateEnd",
                                    value: date
                                }
                            })
                        }}
                    />
                </div>
                {/* TODO: introduce role management as in okcomply frontend */}
                {okAudit.role === ROLES.QUALITY_MANAGER &&
                    <div className="col">
                        <InputSelect
                            onChange={onChange}
                            defaultValue={filters?.auditor}
                            name="auditor"
                            label={t("inspection.filter.inspector")}
                            items={userOptions}
                            dark
                        />
                    </div>
                }
            </div>
        </div>
    );
}

FilterInspections.propTypes = {
    onClose: PropTypes.func.isRequired,
};


export default FilterInspections;